import { SearchAirspaceElementsOptions } from "@dtm-frontend/shared/map/geo-zones";
import { FilterConditions, Sorting } from "../models/mission-search.models";

export namespace MissionSearchActions {
    export class SetFilterConditions {
        public static readonly type = "[MissionSearch] Set filter conditions";

        constructor(public filterConditions: FilterConditions | undefined) {}
    }

    export class SetMissionsOrder {
        public static readonly type = "[MissionSearch] Set missions order";

        constructor(public sorting: Sorting) {}
    }

    export class FetchMissions {
        public static readonly type = "[MissionSearch] Fetch missions";

        constructor(public maxResultsSize?: number) {}
    }

    export class GetMission {
        public static readonly type = "[MissionSearch] Get mission";

        constructor(public missionId: string) {}
    }

    export class GetNearbyMissions {
        public static readonly type = "[MissionSearch] Get nearby missions";

        constructor(public nearbyMissionsIds: string[], public collisionMissionsIds: string[]) {}
    }

    export class GetMissionRoute {
        public static readonly type = "[MissionSearch] Get mission route";

        constructor(public routeId: string) {}
    }

    export class ClearMissionData {
        public static readonly type = "[MissionSearch] Clear mission data";

        constructor() {}
    }

    export class SearchAirspaceElements {
        public static readonly type = "[MissionSearch] Search airspace elements";

        constructor(public options: SearchAirspaceElementsOptions) {}
    }

    export class ClearAirspaceElements {
        public static readonly type = "[MissionSearch] Clear airspace elements";
    }

    export class FetchMissionsRoutes {
        public static readonly type = "[MissionSearch] Fetch missions routes";

        constructor(public missionsIds: string[]) {}
    }

    export class SaveMissionsVisibilityOnMap {
        public static readonly type = "[MissionSearch] Save missions visibility on map";

        constructor(public areMissionsVisibleOnMap: boolean) {}
    }
}
